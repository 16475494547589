<template>
  <v-card max-height="400px" class="search-model-box">
    <v-text-field
      full-width
      solo
      clearable
      prepend-inner-icon="mdi-magnify"
      hide-details
      v-model="modelSearch"
      v-on:keyup.native="search"
      @change="search"
      @click:prepend-inner="search"
      @click:clear="
        modelItems = [];
        selectedModel = '';
        search(null, '');
      "
      :loading="modelSearchLoading"
    >
    </v-text-field>
    <v-list dense>
      <v-list-item class="primary--text">
        <slot></slot>
      </v-list-item>
      <v-list-item-group v-model="selectedModel" class="search-model-box__list">
        <v-list-item
          :id="item.id"
          color="primary"
          v-for="item in modelItems"
          :key="item.id"
          v-text="item.title"
          :value="item"
          @click="modelSearch = item.title"
        ></v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<style lang="scss">
.search-model-box {
  &__list {
    max-height: 200px;
    overflow: auto;
  }
}
</style>

<script>
import {
  getMachineModelsList,
  getNodeModelsList,
  getAllToolsAndSkills,
  getAllLicenses
} from "@/api";

export default {
  name: "models-list",
  props: ["selected", "type", "specialization", "refresh"],

  // catalog[type].search: undefined,
  // catalog[type].items: [],
  // catalog[type].loading: false,

  data: () => ({
    modelSearch: "",
    modelItems: [],
    modelSearchLoading: false,
    selectedModel: null
  }),
  methods: {
    search(item, searchName) {
      this.modelSearch = searchName !== undefined ? searchName : this.modelSearch;
      this.selectedModel = null;
      if (this.modelSearchLoading) return;
      this.modelSearchLoading = true;

      switch (this.type) {
        case "machine":
          this.machinesList();
          break;
        case "node":
          this.nodesList();
          break;
        case "skillsTools":
          this.skillsToolsList();
          break;
        case "license":
          getAllLicenses(this.modelSearch)
            .then(r => {
              this.modelItems = r.data.trade_licenses;
            })
            .catch(err => {
              console.error(err);
            })
            .finally(() => (this.modelSearchLoading = false));
          break;
        case "certificate":
          getAllLicenses(this.modelSearch)
            .then(r => {
              this.modelItems = r.data.professional_certificates;
            })
            .catch(err => {
              console.error(err);
            })
            .finally(() => (this.modelSearchLoading = false));
          break;
      }
    },

    machinesList() {
      getMachineModelsList({ title: this.modelSearch })
        .then(r => {
          this.modelItems = r.data;
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => (this.modelSearchLoading = false));
    },

    nodesList() {
      getNodeModelsList({ title: this.modelSearch })
        .then(r => {
          this.modelItems = r.data;
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => (this.modelSearchLoading = false));
    },

    skillsToolsList() {
      getAllToolsAndSkills(this.specialization)
        .then(r => {
          this.modelItems = r.data.skills.map(i => {
            const title =
              i["tool"] != null
                ? `${i["skill_name"]}     ←•→     ${i["tool"]}`
                : `${i["skill_name"]}`;

            return {
              id: i.id,
              title: title
            };
          });
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => (this.modelSearchLoading = false));
    },

    licensesList() {
      getAllLicenses(this.modelSearch)
        .then(r => {
          this.modelItems = r.data.licenses;
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => (this.modelSearchLoading = false));
    }
  },
  mounted() {
    this.search();
  },
  watch: {
    selectedModel: function(val) {
      this.$emit("update:selected", val);
    },
    refresh: function(val) {
      if (val) {
        this.search();
      }
    }
  }
};
</script>
