<template>
  <v-form>
    <v-row>
      <v-col>
        <v-row>
          <v-col>
            <h3>{{ $t("Education") }}</h3>
            <p class="under-title required">
              {{ $t("Please select education") }}
            </p>
            <v-divider class="mb-4" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div v-for="education in educations" :key="education.id">
              <label :class="{ 'input-label': true }">
                {{ education.title | startCase }}
              </label>
              <v-text-field
                solo
                :class="{ 'input-label': true }"
                :label="education.title"
                :rules="[rules.counter, rules.education]"
                :placeholder="`${$t('Enter')} ${education.title}`"
                v-model="education.value"
              >
                <template v-slot:append-outer>
                  <v-btn
                    fab
                    depressed
                    color="primary"
                    :disabled="!education.value"
                    @click="addEducation(education)"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-chip
              close
              color="white"
              class="chip-shadow"
              v-for="(education, index) in this.user_educations"
              :key="index"
              @click:close="removeEducation(education)"
              :title="education.kind"
            >
              <strong> {{ education.kind | startCase }} </strong>&nbsp;/&nbsp;
              <span> {{ education.name }} </span>
            </v-chip>
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <v-row>
          <v-col>
            <h3>{{ $t("Additional Info") }}</h3>
            <v-divider class="mb-4" />
            <v-checkbox
              label="Errors and omissions liability insurance (E&O)"
              v-model="data.additionals.business_insurance"
            />
            <p>
              <strong>Attention!</strong> In order to increase your service coverage and sales you
              can use the most powerful ability of ClueXpress - to select two contractors for one
              specific job order and offering them to work together remotely from each other:
              someone as a Technician will be physically present at customer site and work in a
              field and someone as a Remote Expert will assist guiding a Technician step-by-step
              through diagnostic procedure and repair process. In this case Technician will use an
              Augmented Reality app for smart phone or smart glasses and an Expert will send visual
              instructions which are “sticky” to real objects along with verbal instructions from
              computer or smartphone.
            </p>
            <p>
              In order to get such a contract with remote guidance capabilities you have to check
              technical compatibility of your device and download
              <a href="https://cluexpress.com/arsolutions" target="_blank"
                >Remote Assistance software tool here</a
              >
              for your smart phone, smart glasses and computer. An extended free trial allows you to
              get some self-training and experience with the software tool. After that you can check
              the box below if you want to bid and be selected for contracts with Remote Assistance.
            </p>
            <p>
              You can learn more about
              <a href="https://cluexpress.com/arsolutions" target="_blank"
                >Remote Assistance software tool</a
              >
              capabilities and its application <br />
              with recommendations in
              <a
                href="https://docs.google.com/document/d/1-Y8XrNNQ_E-EMcx8IgcjLflSbeeAtpRzJeurYi_5rK0/edit?usp=sharing"
                target="_blank"
                >FAQ</a
              >
              and our Manual.
            </p>
            <v-checkbox
              label=" I have a tool for remote assistance"
              v-model="data.additionals.remote_assistance"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3>{{ $t("Specialization") }}</h3>
        <p class="under-title required">
          Please, select at least one specialization so that we could find work for you.
        </p>
        <v-divider class="mb-4" />
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="spec in specExpertItems" :key="spec.specialism">
        <v-checkbox :label="spec.text" v-model="spec.active" />
        <div v-if="spec.active">
          <label class="input-label required">{{ $t("Work experience") }}</label>
          <v-select
            :items="workExperienceItems"
            v-model="spec.work_experience"
            solo
            :label="$t('Experience')"
          ></v-select>
          <label class="input-label required">{{ $t("Hourly rate") }}</label>
          <v-text-field
            :label="$t('Hourly rate')"
            solo
            prefix="$"
            suffix="/h"
            type="number"
            min="0"
            v-model="spec.hourly_rate"
          ></v-text-field>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-row>
          <v-col>
            <h3>{{ $t("Service distance coverage") }}</h3>
            <v-divider class="mb-4" />
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4">
            <label class="input-label required">{{ $t("Radius km") }}</label>
            <v-text-field
              :label="$t('Working area')"
              solo
              suffix="km"
              type="number"
              :step="1"
              :max="10000"
              :min="1"
              :rules="[rules.onlyDigits, rules.radiusKm]"
              v-model="data.expert.working_area"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-row>
          <v-col>
            <h3>{{ $t("Equipment repair experience") }}</h3>
            <v-divider class="mb-4" />
          </v-col>
        </v-row>
        <v-row>
          <v-col md="6">
            <v-row>
              <v-col md="10">
                <models-list
                  key="machine"
                  :refresh.sync="needUpdateMachineList"
                  :selected.sync="selectedMachine"
                  type="machine"
                >
                  <add-model-dialog
                    @modelWasAdded="needUpdateMachineList = $event"
                    :type="'machine'"
                    :key="'machine'"
                  ></add-model-dialog>
                </models-list>
              </v-col>
              <v-col md="2">
                <v-btn fab color="primary" @click="addMachine" :disabled="!selectedMachine">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col md="6">
            <div class="h-choose-double-box">
              <div class="d-flex" v-for="machine in getMachinesPageList" :key="machine.id">
                <span class=" mr-auto">{{ machine.title }}</span>
                <span class="text-secondary mr-2">
                  {{ $t("Manufacturer") }}: {{ machine.manufacturer_name }} / {{ $t("Brand") }}:
                  {{ machine.brand_name }}</span
                >
                <v-btn
                  icon
                  x-small
                  color="primary"
                  rounded
                  class=""
                  @click="removeMachine(machine.id)"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
            <div class="text-center">
              <v-pagination
                v-model="machinePage"
                v-if="data.machine_models.length > elementsPerPage"
                circle
                :length="machinesPageLength"
              ></v-pagination>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-row>
          <v-col>
            <h3>{{ $t("Assembly repair experience") }}</h3>
            <v-divider class="mb-4" />
          </v-col>
        </v-row>
        <v-row>
          <v-col md="6">
            <v-row>
              <v-col md="10">
                <models-list
                  key="node"
                  :refresh.sync="needUpdateNodeList"
                  :selected.sync="selectedNode"
                  type="node"
                >
                  <add-node-dialog
                    @modelWasAdded="needUpdateNodeList = $event"
                    :type="'node'"
                    :key="'node'"
                  ></add-node-dialog>
                </models-list>
              </v-col>
              <v-col md="2">
                <v-btn fab color="primary" @click="addNode" :disabled="!selectedNode">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col md="6">
            <div class="h-choose-double-box">
              <div class="d-flex" v-for="node in getNodesPageList" :key="node.id">
                <span class=" mr-auto">{{ node.title }}</span>
                <span class="secondary-text mr-2">
                  {{ $t("Manufacturer") }} : {{ node.manufacturer_name }} / {{ $t("Brand") }}:
                  {{ node.brand_name }}
                </span>
                <v-btn icon x-small rounded class="" color="primary" @click="removeNode(node.id)">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
            <div class="text-center">
              <v-pagination
                v-model="nodePage"
                v-if="data.node_models.length > elementsPerPage"
                circle
                :length="nodesPageLength"
              ></v-pagination>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-row>
          <v-col>
            <h3>{{ $t("Professional Certificates") }}</h3>
            <v-divider class="mb-4" />
          </v-col>
        </v-row>
        <v-row>
          <v-col md="6">
            <v-row>
              <v-col md="10" sm="6" xs="12">
                <search-items
                  search-placeholder="certificates"
                  type="certificates"
                  :catalogue-data.sync="certificates"
                  :changeItem="changeCertificate"
                  :search="getAllCertificates"
                >
                  <v-btn
                    :ripple="false"
                    @click="isShownAddNewCertificateBtn = true"
                    text
                    small
                    color="primary"
                    class="btn-add-new-item "
                  >
                    <span class="text-left"> + {{ $t(`add new item`) }}</span>
                  </v-btn>
                </search-items>
              </v-col>
              <v-col md="2">
                <v-btn fab color="primary" @click="addLicense" :disabled="!certificates.selected">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="isShownAddNewCertificateBtn">
              <v-col md="10">
                <v-text-field
                  :label="$t('Enter New Certificate Title')"
                  solo
                  v-model="newCertificate"
                ></v-text-field>
              </v-col>
              <v-col md="2">
                <v-btn fab color="primary" @click="addCertificate" :disabled="!newCertificate">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col md="6">
            <div class="h-choose-double-box">
              <div class="d-flex" v-for="license in getLicensesPageList" :key="license.id">
                <span class=" mr-auto">{{ license.title }}</span>
                <v-btn
                  icon
                  x-small
                  rounded
                  class=""
                  color="primary"
                  @click="removeLicense(license.id)"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
            <div class="text-center">
              <v-pagination
                v-model="licensePage"
                v-if="data.licenses.length > elementsPerPage"
                circle
                :length="licensesPageLength"
              ></v-pagination>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn color="primary" class="float-left" @click="submitExpert">
          {{ $t("Save") }}
        </v-btn>
      </v-col>
    </v-row>
    <errors-snackbar :show.sync="showWarnings" :errors="warnings"> </errors-snackbar>
    <info-snackbar :show.sync="showInfo" :message="infoMessage"> </info-snackbar>
  </v-form>
</template>
<style></style>
<script>
import ModelsList from "@/components/ModelsList.vue";
import ErrorsSnackbar from "@/components/ErrorsSnackbar.vue";
import InfoSnackbar from "@/components/InfoSnackbar.vue";
import AddNewItem from "@/components/AddNewItem.vue";
import SearchItems from "@/components/SearchItems.vue";
import {
  map as _map,
  keys,
  find as _find,
  startCase as _startCase,
  capitalize,
  cloneDeep
} from "lodash";

import {
  updateExpertProfile,
  getAllUserSpecializations,
  getAllUserEducation,
  getAllLicenses,
  getUserEducationList,
  createUserEducation,
  deleteNodeModel,
  deleteUserEducation,
  deleteMachineModel,
  createLicenses,
  deleteLicenseExpert
} from "@/api";

import { cleanSource } from "@/helpers/workingWithObjects";

const CERTIFICATES_DEFAULT = {
  search: undefined,
  items: [],
  loading: false,
  selected: ""
};

export default {
  name: "expert-profile",
  components: {
    ModelsList,
    "search-items": SearchItems,
    ErrorsSnackbar,
    InfoSnackbar,
    "add-model-dialog": AddNewItem,
    "add-node-dialog": AddNewItem
  },
  props: ["data", "isActive"],
  data: () => ({
    machinePage: 1,
    nodePage: 1,
    licensePage: 1,
    elementsPerPage: 10,
    needUpdateNodeList: false,
    needUpdateMachineList: false,
    isShownAddNewCertificateBtn: false,
    newCertificate: "",
    educationKinds: [],
    educations: [{ kind: "", name: "" }],
    user_educations: [],
    specExpertItems: [],
    selectedNode: null,
    selectedMachine: null,
    selectedLicense: null,
    showWarnings: false,
    showInfo: false,
    infoMessage: "",
    warnings: [],
    specsApi: {},
    certificates: cloneDeep(CERTIFICATES_DEFAULT),
    rules: {
      counter: value => (value && value.length <= 100) || "Max 100 characters",
      radiusKm: value => value < 10000 || "Working area should be less or equal 10000",
      onlyDigits: value => /^\d*$/.test(value) || "Input is invalid",
      education: value => {
        const pattern = /[^\da-zA-Z-+@ ]/;
        return !pattern.test(value) || "Input is invalid";
      }
    }
  }),
  watch: {
    isActive: function(isActive) {
      if (isActive) {
        this.getEducationsKinds();
      }
    }
  },
  computed: {
    selectedNodes() {
      return this.data.node_models.map(i => i.id);
    },

    selectedMachines() {
      return this.data.machine_models.map(i => i.id);
    },

    selectedLicenses() {
      return this.data.licenses.map(i => i.id);
    },

    getLicensesPageList() {
      return this.sliceArrayByPages(this.data.licenses, this.licensePage);
    },

    getMachinesPageList() {
      return this.sliceArrayByPages(this.data.machine_models, this.machinePage);
    },

    getNodesPageList() {
      return this.sliceArrayByPages(this.data.node_models, this.nodePage);
    },

    licensesPageLength() {
      return Math.ceil(this.data.licenses.length / this.elementsPerPage);
    },

    machinesPageLength() {
      return Math.ceil(this.data.machine_models.length / this.elementsPerPage);
    },

    nodesPageLength() {
      return Math.ceil(this.data.node_models.length / this.elementsPerPage);
    }
  },
  methods: {
    sliceArrayByPages(arrayList, currentPage) {
      const offset = this.elementsPerPage * currentPage;
      const index = offset - this.elementsPerPage;

      return arrayList.slice(index, offset);
    },

    getEducationsKinds() {
      getAllUserEducation()
        .then(r => {
          this.educationKinds = r.data.education_kinds;
          this.educations = _map(this.educationKinds, (id, title) => ({
            id,
            title,
            value: ""
          }));

          this.getEducationList();
        })
        .catch(err => {
          console.error(err);
        });
    },

    getSpecialisations() {
      getAllUserSpecializations()
        .then(r => {
          this.specsApi = r.data;
          this.specExpertItems = _map(r.data["expert_specializations"], (id, title) => ({
            text: _startCase(title),
            title: title,
            specialism: id,
            work_experience: "",
            hourly_rate: 0,
            active: false
          }));
          this.specTechItems = keys(r.data["technician_specializations"]);
          this.workExperienceItems = _map(r.data["work_experiences"], (id, title) => ({
            text: capitalize(_startCase(title)),
            value: id
          }));
          this.setSpecialisations();
        })
        .catch(err => {
          console.error(err);
        });
    },

    addEducation(education) {
      if (!education.value) return;
      const data = {
        kind: education.id,
        name: education.value,
        user_profile_id: this.data.expert.id
      };
      createUserEducation(data)
        .then(() => {
          console.info("Education item was created");
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.getEducationList();
        });
    },

    removeEducation(education) {
      if (this.user_educations.length > 1) {
        deleteUserEducation(education.id)
          .then(() => {
            console.info("Education item was removed");
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            this.getEducationList();
          });
      } else {
        this.warnings = [this.$t("Please add education before delete the last one")];
        this.showWarnings = true;
      }
    },

    getEducationList() {
      getUserEducationList()
        .then(r => {
          this.user_educations = r.data.user_educations;
        })
        .catch(err => {
          this.user_educations = [];
          console.log(err);
        });
    },

    setSpecialisations() {
      if (!this.data.specializations.length) return;
      this.specExpertItems = _map(this.specExpertItems, item => {
        const spec = _find(this.data.specializations, i => i["specialism"] === item.title);
        if (spec) {
          return {
            text: _startCase(spec["specialism"]),
            title: spec["specialism"],
            specialism: this.specsApi["expert_specializations"][spec["specialism"]],
            work_experience: this.specsApi["work_experiences"][spec["work_experience"]],
            hourly_rate: spec["hourly_rate"] + "",
            active: true
          };
        } else {
          return item;
        }
      });
    },

    submitExpert() {
      this.warnings = [];
      this.showWarnings = false;
      const userEducation = cleanSource(this.user_educations).map(educationItem => {
        educationItem.kind = this.educationKinds[educationItem.kind];
        return educationItem;
      });

      const data = {
        working_area: +this.data.expert.working_area,
        user_specializations: this.specExpertItems.filter(i => i.active),
        user_educations: userEducation,
        additional_info: this.data.additionals,
        licenses_ids: this.data.licenses.map(i => i.id),
        node_model_ids: this.data.node_models.map(i => i.id),
        machine_model_ids: this.data.machine_models.map(i => i.id)
      };
      updateExpertProfile(data)
        .then(() => {
          this.showWarnings = false;
          this.infoMessage = this.$t("Expert profile was successfully updated!");
          this.showInfo = true;
        })
        .catch(err => {
          this.warnings =
            err.response.status !== 500
              ? [err.response.data.errors.join("\n ")]
              : [err.response.data.error];
          this.showWarnings = true;
        });
    },

    addLicense() {
      if (!this.certificates.selected) return;
      if (_find(this.data.licenses, { id: this.certificates.selected.id })) return;
      this.data.licenses.push(this.certificates.selected);
    },

    removeLicense(license) {
      deleteLicenseExpert(license)
        .catch(e => {
          this.warnings = e.data.errors;
          console.error(e);
        })
        .finally(() => {
          this.data.licenses = this.data.licenses.filter(i => i.id !== license);
        });
    },

    addNode() {
      if (!this.selectedNode) return;
      if (_find(this.data.node_models, { id: this.selectedNode.id })) return;
      this.data.node_models.push(this.selectedNode);
    },

    removeNode(id) {
      deleteNodeModel(id)
        .catch(e => {
          this.warnings = e.data.errors;
          console.error(e);
        })
        .finally(() => {
          this.data.node_models = this.data.node_models.filter(i => i.id !== id);
        });
    },

    addMachine() {
      if (!this.selectedMachine) return;
      if (_find(this.data.machine_models, { id: this.selectedMachine.id })) return;
      this.data.machine_models.push(this.selectedMachine);
    },

    removeMachine(id) {
      deleteMachineModel(id)
        .catch(e => {
          this.warnings = e.data.errors;
          console.error(e);
        })
        .finally(() => {
          this.data.machine_models = this.data.machine_models.filter(i => i.id !== id);
        });
    },

    getAllCertificates(title) {
      this.certificates.loading = true;
      getAllLicenses(title)
        .then(r => {
          this.certificates.items = r.data.professional_certificates;
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => (this.certificates.loading = false));
    },

    changeCertificate(item) {
      this.certificates.search = item.title;
    },

    addCertificate() {
      if (this.newCertificate) {
        createLicenses({ name: this.newCertificate })
          .then(() => {
            this.infoMessage = this.$t("Certificate was successfully created!");
            this.getAllCertificates();
            this.showInfo = true;
          })
          .catch(e => {
            this.warnings = e.data.errors;
            console.error(e);
          })
          .finally(() => {
            this.newCertificate = "";
            this.isShownAddNewCertificateBtn = false;
          });
      }
    }
  },
  mounted() {
    this.getEducationsKinds();
    this.getSpecialisations();
    this.getAllCertificates();
  }
};
</script>
