import { googleGeoParse } from "@/helpers/workingWithObjects";

const LOCATION_DEFAULTS = {
  country: "",
  state: "",
  city: "",
  office: "",
  zip_code: ""
};

const CONTACTS_DEFAULTS = {
  phone_number: "",
  skype: ""
};

const COMPANY_DEFAULTS = {
  name: "",
  title: "",
  website: "",
  email: "",
  zip_code: ""
};

const DEFAULT_PROFILES = {
  general_profile: {
    personal: {
      id: null,
      bio: null,
      name: null,
      avatar_url: null,
      type: "GeneralProfile",
      enabled: false,
      tax_id: null
    },
    location: LOCATION_DEFAULTS,
    languages: null,
    roles: null,
    contacts: CONTACTS_DEFAULTS
  },
  customer_profile: {
    customer: {
      id: 2,
      enabled: false
    },
    company: COMPANY_DEFAULTS,
    insurance: {
      active: false
    }
  },
  client_profile: {
    customer: {
      id: 2,
      enabled: false
    },
    company: COMPANY_DEFAULTS,
    insurance: {
      active: false
    }
  },
  expert_profile: {
    expert: {
      id: 3,
      enabled: false,
      working_area: null
    },
    educations: [],
    specializations: [],
    licenses: [],
    additionals: { business_insurance: null, remote_assistance: null },
    node_models: [],
    machine_models: []
  },
  technician_profile: {
    additionals: { business_insurance: null, remote_assistance: null },
    educations: [],
    node_models: [],
    machine_models: [],
    specializations: [],
    technician: {
      id: 4,
      enabled: false,
      working_area: null
    },
    skills: {
      electromechanic: [],
      electrician: [],
      mechanic: [],
      electronic: []
    },
    licenses: []
  }
};
import uid from "uid";
import {
  getAllUserProfiles,
  updateGeneralProfile,
  getLanguagesList,
  getPresignedLink
} from "@/api";
import { isEmpty, keys, omit, map as _map, invert, fromPairs } from "lodash";
import ExpertProfile from "@/pages/Profiles/User/ExpertProfile.vue";
import CustomerProfile from "@/pages/Profiles/User/CustomerProfile.vue";
import TechnicianProfile from "@/pages/Profiles/User/TechnicianProfile.vue";
import ErrorsSnackbar from "@/components/ErrorsSnackbar.vue";
import InfoSnackbar from "@/components/InfoSnackbar.vue";

export default {
  components: { ExpertProfile, CustomerProfile, TechnicianProfile, ErrorsSnackbar, InfoSnackbar },
  data: () => ({
    showWarningsGeneral: false,
    warningsGeneral: [],
    activeTab: "General",
    allProfiles: DEFAULT_PROFILES,
    languagesItems: [],
    levelsItems: [],
    user_languages: [], //{ language_id: 1, level: 2 }
    specExpertItems: [],
    specTechItems: [],
    workExperienceItems: [],
    educationKinds: [],
    educations: [],
    userModels: [],
    newAvatar: null,
    newLang: {
      id: null,
      title: null,
      level: null
    },
    showInfo: false,
    infoMessage: "",
    rules: {
      avatar: value => !value || value.size < 2000000 || "Avatar size should be less than 2 MB!",
      phone: value => {
        const pattern = /[^\d+]/;
        return !pattern.test(value) || "Phone is invalid";
      },
      userName: value => {
        const pattern = /[^a-zA-Z-. ]/;
        return !pattern.test(value) || "Input is invalid please use only letters A-Z and spaces";
      }
    }
  }),
  computed: {
    languagesSelectItems(): any {
      return this.languagesItems.map(l => {
        return { id: l[0], title: l[1] };
      });
    },

    showWarningAboutFillIn() {
      const profile = this.allProfiles["general_profile"];
      const contacts = profile.contacts;
      const contactsFilled = Boolean(contacts.phone_number.length && contacts.skype.length);
      const languagesFilled = profile.languages
        ? Boolean(Object.keys(profile.languages).length)
        : false;
      const nameFilled = Boolean(profile.personal.name && profile.personal.name.length);
      const locationKeys = Object.keys(profile.location);
      let locationFilled = true;
      for (const field in locationKeys) {
        if (profile.location[locationKeys[field]].length < 1) {
          locationFilled = false;
        }
      }
      return !contactsFilled || !languagesFilled || !nameFilled || !locationFilled;
    }
  },
  methods: {
    changeAvatar(file) {
      const fileParams = {
        file_name: uid(),
        file_type: file.name.split(".").pop(),
        file_instance_name: "avatar_assets"
      };

      const availableTypes = ["image/png", "image/jpeg", "image/bmp"];
      if (!availableTypes.includes(file.type)) {
        this.showWarningsGeneral = true;
        this.warningsGeneral = [this.$t("File should be image!")];
        this.allProfiles["general_profile"].personal.avatar_url = "";
        return false;
      }

      if (file.size >= 2000000) {
        this.showWarningsGeneral = true;
        this.warningsGeneral = [this.$t("Avatar size should be less than 2 MB!")];
        this.allProfiles["general_profile"].personal.avatar_url = "";
        return false;
      }

      getPresignedLink(fileParams, file)
        .then(res => {
          this.allProfiles["general_profile"].personal.avatar_url = res.url.split("?")[0];
        })
        .catch(e => {
          console.error(e);
        });
    },

    getAllProfiles() {
      getAllUserProfiles()
        .then(r => {
          this.allProfiles = r.data ? r.data.profiles : DEFAULT_PROFILES;
          // TODO: pull an owl on a globe
          if (!this.allProfiles["general_profile"].location) {
            this.allProfiles["general_profile"].location = LOCATION_DEFAULTS;
          }
          if (isEmpty(this.allProfiles["general_profile"].languages)) {
            this.allProfiles["general_profile"].languages = null;
          }
          if (!this.allProfiles["general_profile"].contacts) {
            this.allProfiles["general_profile"].contacts = CONTACTS_DEFAULTS;
          }
          if (!this.allProfiles["customer_profile"].company) {
            this.allProfiles["customer_profile"].company = COMPANY_DEFAULTS;
          }
          if (!this.allProfiles["customer_profile"].insurance) {
            this.allProfiles["customer_profile"].insurance = {
              active: false
            };
          }
          if (!this.allProfiles["expert_profile"].additionals) {
            this.allProfiles["expert_profile"].additionals = {
              business_insurance: false,
              remote_assistance: false
            };
          }
          if (!this.allProfiles["technician_profile"].additionals) {
            this.allProfiles["technician_profile"].additionals = {
              business_insurance: false,
              remote_assistance: false
            };
          }
          // this.user_languages = this.allProfiles["general_profile"].languages.map();
        })
        .catch(err => {
          console.error(err);
        });
    },

    submitGeneral() {
      this.warningsGeneral = [];
      this.showWarningsGeneral = false;
      const data = {
        avatar_url: this.allProfiles["general_profile"].personal.avatar_url,
        name: this.allProfiles["general_profile"].personal.name,
        bio: this.allProfiles["general_profile"].personal.bio,
        tax_id: this.allProfiles["general_profile"].personal.tax_id,
        user_location: this.allProfiles["general_profile"].location,
        user_languages: _map(this.allProfiles["general_profile"].languages, (lvl, lang) => {
          return {
            language_id: invert(fromPairs(this.languagesItems))[lang],
            level: invert(this.levelsItems)[lvl]
          };
        }),
        user_contacts: this.allProfiles["general_profile"].contacts
      };

      updateGeneralProfile(data)
        .then(() => {
          this.showWarningsGeneral = false;
          this.showInfo = true;
          this.infoMessage = this.$t("General profile was successfully updated!");
        })
        .catch(err => {
          this.showWarningsGeneral = true;
          this.warningsGeneral =
            err.response.status !== 500 ? err.response.data.errors[0] : [err.response.data.error];
        });
    },

    getLanguages() {
      getLanguagesList()
        .then(r => {
          this.languagesItems = r.data ? r.data.languages : [];
          this.levelsItems = r.data ? keys(r.data.levels) : [];
        })
        .catch(err => {
          console.error(err);
        });
    },

    addLanguage() {
      this.allProfiles["general_profile"].languages = {
        ...this.allProfiles["general_profile"].languages,
        [this.newLang.id]: this.newLang.level
      };
      // [this.newLang.id] = this.newLang.level;
      this.newLang = {
        id: null,
        title: null,
        level: null
      };
    },

    deleteLang(lang) {
      this.allProfiles["general_profile"].languages = omit(
        this.allProfiles["general_profile"].languages,
        lang
      );
    },

    getGeoData(e) {
      const parsedData = googleGeoParse(e);
      this.allProfiles["general_profile"].location["country"] = parsedData.country || "";
      this.allProfiles["general_profile"].location["state"] = parsedData.state || parsedData.city;
      this.allProfiles["general_profile"].location["city"] = parsedData.city || "";
      this.allProfiles["general_profile"].location["zip_code"] = parsedData.zip_code || "";
      this.allProfiles["general_profile"].location["office"] = parsedData.app_address || "";
    },

    clearGeo() {
      this.allProfiles["general_profile"].location = LOCATION_DEFAULTS;
    }
  },
  mounted() {
    this.getAllProfiles();
    this.getLanguages();
  }
};
