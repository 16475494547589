<template>
  <div>
    <v-row v-if="tech.isContactsShown">
      <v-col>
        <h3>{{ $t("Technic info") }}</h3>
        <v-divider class="mb-4" />
      </v-col>
    </v-row>
    <v-row v-if="tech.isContactsShown">
      <v-col>
        <v-skeleton-loader transition="scale-transition" :loading="loading" type="paragraph">
          <table>
            <tr v-if="tech.skype !== null">
              <td>{{ $t("Skype") }}:</td>
              <td>
                <a :href="`skype:${tech.skype}?chat`">
                  {{ tech.skype }}
                </a>
              </td>
            </tr>
            <tr v-if="tech.phone !== null">
              <td>{{ $t("Phone") }}:</td>
              <td>
                <a :href="`tel:${tech.phone}`">
                  {{ tech.phone }}
                </a>
              </td>
            </tr>
            <tr v-if="tech.email !== null">
              <td>{{ $t("Email") }}:</td>
              <td>
                <a :href="`mailto:${tech.email}`">
                  {{ tech.email }}
                </a>
              </td>
            </tr>
          </table>
        </v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3>{{ $t("Specialization") }}</h3>
        <v-divider class="mb-4" />
      </v-col>
    </v-row>

    <v-btn-toggle v-model="activeSkillsToolsTab" mandatory class="row flex-fill d-flex">
      <v-col v-for="(spec, key) in tech.specializations" :key="spec.title">
        <v-checkbox
          :label="spec.specialism | startCase"
          readonly
          :value="spec.specialism"
          v-model="spec.specialism"
        />
        <p>
          <v-chip v-if="isAuthorised" color="white" class="chip-shadow pa-4 mx-2">
            {{ spec.work_experience | startCase }}
          </v-chip>
        </p>
        <p v-if="isAuthorised">
          <v-chip color="white" class="chip-shadow pa-4 mx-2">${{ spec.hourly_rate }}/h</v-chip>
        </p>
        <v-btn :outlined="activeSkillsToolsTab !== key" color="primary" :value="key">
          {{ $t("Show skills") }}
        </v-btn>
      </v-col>
    </v-btn-toggle>

    <v-row>
      <v-col>
        <v-row>
          <v-col>
            <h3>{{ $t("Skills") }}</h3>
            <v-divider class="mb-4" />
          </v-col>
        </v-row>
        <v-skeleton-loader transition="scale-transition" :loading="loading" type="list-item">
          <v-window v-model="activeSkillsToolsTab" class="pa-4">
            <v-window-item
              v-for="(spec, key) in tech.specializations"
              :key="spec.specialism"
              :value="key"
            >
              <v-row>
                <v-col md="6" v-for="item in tech.skills[spec.specialism]" :key="item.id">
                  <div class="d-flex">
                    <span class="mr-auto pre-text">{{ item.title }}</span>
                  </div>
                </v-col>
              </v-row>
            </v-window-item>
          </v-window>
        </v-skeleton-loader>
      </v-col>
    </v-row>

    <v-row v-if="isAuthorised">
      <v-col>
        <h3>{{ $t("Trade Licenses") }}</h3>
        <v-divider class="mb-4" />
      </v-col>
    </v-row>
    <v-row v-if="isAuthorised">
      <v-skeleton-loader transition="scale-transition" :loading="loading" type="list-item">
        <v-list color="transparent" class="pa-0">
          <v-list-item v-for="license in tech.licenses" :key="license.name">
            <v-list-item-content>
              {{ license.title }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-skeleton-loader>
    </v-row>
  </div>
</template>

<script>
import { keys, map as _map } from "lodash";
import { me } from "@/auth/auth";

export default {
  props: {
    tech: {
      type: Object
    }
  },
  components: {},
  data: function() {
    return {
      activeSkillsToolsTab: 0,
      isAuthorised: me() || false,
      specTechItems: [],
      workExperienceItems: [],
      loading: true
    };
  },
  methods: {
    getSpecialisations() {
      this.workExperienceItems = keys(this.tech["work_experiences"]);
      this.loading = false;
    },
    getSkillsTools() {
      for (const index in this.tech.skills) {
        if (Object.hasOwnProperty.call(this.tech.skills, index)) {
          _map(this.tech.skills[index], item => {
            item["title"] =
              item["tool"] != null ? `${item["skill_name"]}` : `${item["skill_name"]}`;
            return item;
          });
        }
      }
    }
  },
  mounted() {
    this.getSpecialisations();
    this.getSkillsTools();
  }
};
</script>

<style></style>
