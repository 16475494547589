<template>
  <div>
    <v-row>
      <v-col>
        <h3>{{ $t("Company info") }}</h3>
        <v-divider class="mb-4" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-skeleton-loader transition="scale-transition" :loading="loading" type="paragraph">
          <table>
            <tr v-for="row in companyTable" :key="row.title">
              <td>{{ row.title }}</td>
              <td>{{ row.value }}</td>
            </tr>
          </table>
        </v-skeleton-loader>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    customer: {
      type: Object
    },
    loading: {
      type: Boolean
    }
  },
  components: {},
  computed: {
    companyTable() {
      return [
        { title: this.$t("Company name"), value: this.customer.company.name || "--" },
        { title: this.$t("Title"), value: this.customer.company.title || "--" },
        { title: this.$t("Website"), value: this.customer.company.website || "--" },
        { title: this.$t("Email"), value: this.customer.company.email || "--" }
      ];
    }
  },
  methods: {}
};
</script>

<style scoped>
td {
  padding: 5px 0;
}
td:first-of-type {
  width: 185px;
  color: #b3b3b3;
}
</style>
