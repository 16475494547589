<template>
  <section>
    <basic-info
      :data="generalInfo"
      :activeProfiles="activeProfiles"
      :mainProfileLoading="mainProfileLoading"
      :active-tab="activeTab"
      :educations="educations"
      @tabChanged="catchTab"
    />
    <component
      :customer="customer"
      :loading="mainProfileLoading"
      :expert="expert"
      :tech="tech"
      :is="currentProfile"
    />
  </section>
</template>

<script>
import CustomerProfile from "@/pages/Profiles/Customer/Customer.vue";
import ExpertProfile from "@/pages/Profiles/Expert/Expert.vue";
import TechProfile from "@/pages/Profiles/Tech/Tech.vue";
import BasicInfo from "@/pages/Profiles/Shared/BasicInfo";
import UserProfileProto from "@/pages/Profiles/Shared/UserProfileProto";
import {
  getCustomerProfile,
  getEducationByUserId,
  getExpertProfile,
  getTechnicianProfile
} from "@/api";

export default {
  extends: UserProfileProto,

  data: function() {
    return {
      mainProfileLoading: true,
      generalInfo: {},
      activeProfiles: {
        expert_profile: {
          enabled: false
        },
        technician_profile: {
          enabled: false
        },
        client_profile: {
          enabled: false
        }
      },
      activeTab: null,
      expert: {
        role: this.$t("Expert"),
        id: null,
        avatar_url: null,
        name: null,
        bio: null,
        languages: {},
        address: {},
        working_area: "",
        insurance: "",
        remote_assistance: "",
        specializations: [],
        email: null,
        phone: null,
        skype: null,
        licenses: [],
        isContactsShown: false
      },
      customer: {
        role: this.$t("Customer"),
        id: 3,
        avatar_url: null,
        name: "null",
        bio: "null",
        email: null,
        phone: null,
        languages: {},
        company: {
          name: null,
          title: null,
          website: null,
          email: null
        },
        address: "null"
      },
      tech: {
        role: this.$t("Technician"),
        id: null,
        avatar_url: null,
        name: null,
        bio: null,
        languages: {},
        address: {},
        working_area: "",
        insurance: "",
        remote_assistance: "",
        specializations: [],
        email: null,
        phone: null,
        skype: null,
        licenses: [],
        isContactsShown: false
      },
      educations: [],
      specExpertItems: [],
      workExperienceItems: []
    };
  },

  components: {
    "customer-profile": CustomerProfile,
    "expert-profile": ExpertProfile,
    "tech-profile": TechProfile,
    "basic-info": BasicInfo
  },

  computed: {
    currentProfile() {
      let mainComponent = this.$route.query.profile_view
        ? this.$route.query.profile_view
        : "customer-profile";

      if (this.activeTab) {
        mainComponent = this.activeTab;
      }

      return mainComponent;
    }
  },

  methods: {
    getData(profiles) {
      this.activeProfiles = profiles;

      if (profiles.expert_profile.enabled) {
        this.getExpertData(profiles.expert_profile.id);
      }

      if (profiles.technician_profile.enabled) {
        this.getTechData(profiles.technician_profile.id);
      }

      if (profiles.client_profile.enabled) {
        this.getCustomerData(profiles.client_profile.id);
      }

      if (profiles) {
        this.getProfileEducationData(profiles.id);
      }
    },

    getTechData(id) {
      getTechnicianProfile(id)
        .then(r => {
          this.tech = { ...this.tech, ...r.data };
          this.tech.isContactsShown =
            this.tech.phone !== null || this.tech.email !== null || this.tech.skype !== null;
          this.profileIsLoaded();
        })
        .catch(err => {
          console.error(err);
        });
    },

    getExpertData(id) {
      getExpertProfile(id)
        .then(r => {
          this.expert = { ...this.expert, ...r.data };
          this.expert.isContactsShown =
            this.expert.phone !== null || this.expert.email !== null || this.expert.skype !== null;

          this.profileIsLoaded();
        })
        .catch(err => {
          console.error(err);
        });
    },

    getCustomerData(id) {
      getCustomerProfile(id)
        .then(r => {
          this.customer = { ...this.customer, ...r.data };
          this.profileIsLoaded();
        })
        .catch(err => {
          console.error(err);
        });
    },

    getProfileEducationData(id) {
      getEducationByUserId({ user_id: id })
        .then(r => {
          this.educations = r.data.educations;
        })
        .catch(err => {
          console.error(err);
        });
    },

    catchTab(tab) {
      switch (tab) {
        case "customer-profile":
          this.generalInfo = this.customer;
          break;
        case "expert-profile":
          this.generalInfo = this.expert;
          break;
        case "tech-profile":
          this.generalInfo = this.tech;
          break;
      }

      this.activeTab = tab;
    },

    profileIsLoaded() {
      this.catchTab(this.activeTab);
      this.mainProfileLoading = false;
    }
  },

  mounted() {
    this.activeTab = this.currentProfile;
  }
};
</script>
