<template>
  <div>
    <v-row v-if="!hideTabs">
      <v-btn-toggle
        class="styled-btn-group"
        v-model="innerTab"
        rounded
        mandatory
        active-class="active-tab-btn"
      >
        <v-btn
          v-if="activeProfiles.client_profile.enabled"
          :outlined="innerTab !== 'customer-profile'"
          color="primary"
          value="customer-profile"
        >
          {{ $t("Client") }}
        </v-btn>
        <v-btn
          v-if="activeProfiles.expert_profile.enabled"
          :outlined="innerTab !== 'expert-profile'"
          color="primary"
          value="expert-profile"
        >
          {{ $t("Expert") }}
        </v-btn>
        <v-btn
          v-if="activeProfiles.technician_profile.enabled"
          :outlined="innerTab !== 'tech-profile'"
          color="primary"
          value="tech-profile"
        >
          {{ $t("Technician") }}
        </v-btn>
      </v-btn-toggle>
    </v-row>
    <v-row>
      <v-col md="auto">
        <v-skeleton-loader
          transition="scale-transition"
          :loading="mainProfileLoading"
          type="avatar"
        >
          <v-avatar size="188" color="secondary" class="mr-3">
            <v-img :src="data.avatar_url" v-if="data.avatar_url"></v-img>
            <v-btn fab x-large v-if="!data.avatar_url">
              <v-icon>mdi-account-outline</v-icon>
            </v-btn>
          </v-avatar>
        </v-skeleton-loader>
      </v-col>
      <v-col>
        <v-row>
          <v-col class="pl-0">
            <v-skeleton-loader
              transition="scale-transition"
              :loading="mainProfileLoading"
              type="heading"
            >
              <h3 class="pl-5 pb-5">{{ data.name || "No name" }}</h3>
            </v-skeleton-loader>
            <v-skeleton-loader
              transition="scale-transition"
              :loading="mainProfileLoading"
              type="list-item-three-line"
            >
              <v-list color="transparent" class="pa-0">
                <template v-if="isAuthorised">
                  <v-list-item v-for="(lvl, lang) in data.languages" :key="lang">
                    <v-chip color="white" class="chip-shadow pa-4 mx-2"
                      >{{ lang }} / {{ lvl }}</v-chip
                    >
                  </v-list-item>
                </template>
                <v-list-item>
                  {{ $t("Location") }}:
                  <v-chip
                    v-if="data.address"
                    color="white"
                    class="chip-shadow pa-4 mx-2 profile-location"
                  >
                    <span class="profile-location__item">
                      {{ data.address.country }}
                    </span>
                    <span class="profile-location__item" v-if="isAuthorised">
                      {{ data.address.state }}
                    </span>
                    <span class="profile-location__item" v-if="isAuthorised">
                      {{ data.address.city }}
                    </span>
                  </v-chip>
                </v-list-item>
                <v-list-item v-if="data.working_area">
                  {{ $t("Working area") }}:
                  <v-chip color="white" class="chip-shadow pa-4 mx-2">
                    {{ data.working_area }} km.
                  </v-chip>
                </v-list-item>
              </v-list>
            </v-skeleton-loader>
          </v-col>
          <v-col class="mr-4">
            <v-row v-if="isAuthorised">
              <v-col>
                <h3>{{ $t("Biography") }}</h3>
                <v-divider class="mb-4" />
              </v-col>
            </v-row>
            <v-row v-if="isAuthorised">
              <v-col>
                <v-skeleton-loader
                  transition="scale-transition"
                  :loading="mainProfileLoading"
                  type="paragraph"
                >
                  <p>
                    {{ data.bio }}
                  </p>
                </v-skeleton-loader>
              </v-col>
            </v-row>

            <v-row v-if="educations && isAuthorised">
              <v-col>
                <h3>{{ $t("Education") }}</h3>
                <v-divider />
              </v-col>
            </v-row>
            <v-row v-if="isAuthorised">
              <v-col>
                <span v-for="(education, index) in educations" :key="index">
                  <v-chip color="white" class="chip-shadow">
                    <strong> {{ education.kind | startCase }} </strong>&nbsp;/&nbsp;
                    <span> {{ education.name }} </span>
                  </v-chip>
                </span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { me } from "@/auth/auth";
export default {
  props: {
    data: {
      type: Object
    },
    activeTab: {
      type: String
    },
    hideTabs: {
      type: Boolean
    },
    mainProfileLoading: {
      type: Boolean
    },
    activeProfiles: {
      type: Object
    },
    educations: {
      type: Array
    }
  },
  data: () => ({
    isAuthorised: me() || false
  }),
  computed: {
    innerTab: {
      get() {
        return this.activeTab;
      },
      set(val) {
        this.$emit("tabChanged", val);
      }
    }
  }
};
</script>

<style lang="scss">
/* !!! Custom styles for v-btn-toggle  */
.styled-btn-group.v-btn-toggle {
  margin: 0 auto;
  border: 1px solid #005fce;
  &--rounded {
    .v-btn {
      border: 0;
      border-left: 0;
      border-right: 0;
      border-color: transparent !important;
      color: #000;
      padding-right: 3rem;
      padding-left: 3rem;
      font-size: 20px;
    }
  }
}
.active-tab-btn {
  border-radius: 24px;
  background-color: #005fce;
  color: #fff !important;
}
.absolute-btn.v-btn {
  top: 38% !important;
  right: -20px !important;
}
.profile-location {
  &__item {
    display: contents;
    &:not(:last-of-type):after {
      content: ", ";
      padding-right: 5px;
    }
  }
}
</style>
