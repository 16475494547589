<template>
  <v-form lazy-validation ref="clientForm" v-model="clientFormValid">
    <v-row>
      <v-col>
        <v-row>
          <v-col>
            <h3>{{ $t("Company info") }}</h3>
            <v-divider class="mb-4" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <label class="input-label required">{{ $t("Company name") }}</label>
            <v-text-field
              solo
              required
              :label="$t('Company name')"
              v-model="data.company.name"
            ></v-text-field>
            <label class="input-label">{{ $t(`Position`) }}</label>
            <v-text-field solo :label="$t(`Position`)" v-model="data.company.title"></v-text-field>
            <label class="input-label">{{ $t(`Website`) }}</label>
            <v-text-field solo :label="$t(`Website`)" v-model="data.company.website"></v-text-field>
            <label class="input-label required">{{ $t("Email") }}</label>
            <v-text-field
              solo
              required
              :label="$t('Email')"
              :rules="[rules.required, rules.email, rules.counter]"
              type="email"
              v-model="data.company.email"
            ></v-text-field>
            <!-- <label class="input-label required"> Zip Code</label>
            <v-text-field
              solo
              required
              label="Zip Code"
              v-model="data.company.zip_code"
            ></v-text-field> -->
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <v-row>
          <v-col>
            <h3>{{ $t("Additional Info") }}</h3>
            <v-divider class="mb-4" />
            <v-checkbox
              v-model="data.insurance.active"
              label="I have Commercial general liability insurance (CGL)"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-btn color="primary" class="float-right" @click="submitClient">{{ $t("Save") }}</v-btn>
    </v-row>
    <errors-snackbar :show.sync="showWarnings" :errors="warnings"> </errors-snackbar>
    <info-snackbar :show.sync="showInfo" :message="infoMessage"> </info-snackbar>
  </v-form>
</template>

<script>
import ErrorsSnackbar from "@/components/ErrorsSnackbar.vue";
import InfoSnackbar from "@/components/InfoSnackbar.vue";
import { updateCustomerProfile } from "@/api";
import { setAsCustomer } from "@/auth/auth";

export default {
  name: "customer-profile",
  components: { ErrorsSnackbar, InfoSnackbar },
  props: ["data"],
  data: () => ({
    clientFormValid: null,
    warnings: [],
    infoMessage: "",
    showWarnings: false,
    showInfo: false,
    rules: {
      required: value => !!value || "Required.",
      counter: value => value.length <= 100 || "Max 100 characters",
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      }
    }
  }),
  methods: {
    submitClient() {
      this.warnings = [];
      this.showWarnings = false;
      const data = {
        user_company: this.data.company,
        insurance: this.data.insurance.active
      };
      updateCustomerProfile(data)
        .then(() => {
          setAsCustomer(true);
          this.showWarnings = false;
          this.showInfo = true;
          this.infoMessage = "Customer profile was successfully updated!";
          this.$root.$children[0].$children[0].isCustomer = true;
        })
        .catch(err => {
          this.showWarnings = true;
          this.warnings =
            err.response.status !== 500 ? err.response.data.errors[0] : [err.response.data.error];
        });
    }
  }
};
</script>
