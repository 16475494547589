<template>
  <v-form>
    <v-row>
      <v-col>
        <v-row>
          <v-col>
            <h3>{{ $t("Education") }}</h3>
            <p class="under-title required">
              {{ $t("Please select education") }}
            </p>
            <v-divider class="mb-4" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div v-for="education in educations" :key="education.id">
              <label :class="{ 'input-label': true }">
                {{ education.title | startCase }}
              </label>
              <v-text-field
                solo
                :class="{ 'input-label': true }"
                :label="education.title"
                :rules="[rules.education, rules.counter]"
                :placeholder="`Enter ${education.title}`"
                v-model="education.value"
              >
                <template v-slot:append-outer>
                  <v-btn
                    fab
                    depressed
                    color="primary"
                    :disabled="!education.value"
                    @click="addEducation(education)"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-chip
              close
              color="white"
              v-for="(education, index) in this.user_educations"
              :key="index"
              class="chip-shadow"
              @click:close="removeEducation(education)"
              :title="education.kind"
            >
              <strong> {{ education.kind | startCase }} </strong>&nbsp;/&nbsp;
              <span> {{ education.name }} </span>
            </v-chip>
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <v-row>
          <v-col>
            <h3>{{ $t("Additional Info") }}</h3>
            <v-divider class="mb-4" />
            <v-checkbox
              label="Errors and omissions liability insurance (E&O)"
              v-model="data.additionals.business_insurance"
            />
            <p>
              <strong>Attention!</strong> In order to increase your service coverage and sales you
              can use the most powerful ability of ClueXpress - to select two contractors for one
              specific job order and offering them to work together remotely from each other:
              someone as a Technician will be physically present at customer site and work in a
              field and someone as a Remote Expert will assist guiding a Technician step-by-step
              through diagnostic procedure and repair process. In this case Technician will use an
              Augmented Reality app for smart phone or smart glasses and an Expert will send visual
              instructions which are “sticky” to real objects along with verbal instructions from
              computer or smartphone.
            </p>
            <p>
              In order to get such a contract with remote guidance capabilities you have to check
              technical compatibility of your device and download
              <a href="https://cluexpress.com/arsolutions" target="_blank"
                >Remote Assistance software tool here</a
              >
              for your smart phone, smart glasses and computer. An extended free trial allows you to
              get some self-training and experience with the software tool. After that you can check
              the box below if you want to bid and be selected for contracts with Remote Assistance.
            </p>
            <p>
              You can learn more about
              <a href="https://cluexpress.com/arsolutions" target="_blank"
                >Remote Assistance software tool</a
              >
              capabilities and its application <br />
              with recommendations in
              <a
                href="https://docs.google.com/document/d/1-Y8XrNNQ_E-EMcx8IgcjLflSbeeAtpRzJeurYi_5rK0/edit?usp=sharing"
                target="_blank"
                >FAQ</a
              >
              and our Manual.
            </p>
            <v-checkbox
              label=" I have a tool for remote assistance"
              v-model="data.additionals.remote_assistance"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3>{{ $t("Specialization") }}</h3>
        <p class="under-title required">
          {{ $t("Please select specialization") }}
        </p>
        <v-divider class="mb-4" />
      </v-col>
    </v-row>

    <v-btn-toggle v-model="activeSkillsToolsTab" mandatory class="row flex-fill d-flex">
      <v-col v-for="(spec, key) in specTechItems" :key="spec.specialism">
        <v-checkbox :label="spec.text" v-model="spec.active" />
        <div v-if="spec.active">
          <label class="input-label required">{{ $t("Work experience") }}</label>
          <v-select
            :items="workExperienceItems"
            v-model="spec.work_experience"
            solo
            :label="$t('Experience')"
          ></v-select>
          <label class="input-label">{{ $t("Hourly rate") }}</label>
          <v-text-field
            label="hourly rate"
            solo
            prefix="$"
            suffix="/h"
            type="number"
            min="0"
            v-model="spec.hourly_rate"
          ></v-text-field>
          <div class="text-center">
            <v-btn :outlined="activeSkillsToolsTab !== key" color="primary" :value="key">
              {{ $t("Skills management") }}
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-btn-toggle>

    <v-row v-if="skillToolsIsShown">
      <v-col>
        <v-row>
          <v-col>
            <h3>{{ $t("Skills and Tools") }}</h3>
            <p class="under-title">
              {{ $t("Please select skill & tool") }}
            </p>
            <v-divider class="mb-4" />
          </v-col>
        </v-row>
        <v-window v-model="activeSkillsToolsTab" class="pa-4">
          <v-window-item v-for="(spec, key) in specTechItems" :key="spec.specialism" :value="key">
            {{ spec.title }}
            <v-row>
              <v-col md="6">
                <v-row>
                  <v-col md="10">
                    <models-list
                      :selected.sync="selectedToolSkill"
                      type="skillsTools"
                      :specialization="spec.title"
                    ></models-list>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      fab
                      color="primary"
                      @click="addSkillTool(spec.title)"
                      :disabled="!selectedToolSkill"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col md="6">
                <div class="h-choose-double-box">
                  <div class="d-flex" v-for="item in getSkillToolsPageList" :key="item.id">
                    <span class="mr-auto pre-text">{{ item.title }}</span>
                    <v-btn
                      icon
                      x-small
                      color="primary"
                      rounded
                      class=""
                      @click="removeSkillTool(item, spec.title)"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </div>
                </div>
                <div class="text-center">
                  <v-pagination
                    v-model="skillsPage[spec.title]"
                    v-if="data.skills[spec.title].length > elementsPerPage"
                    circle
                    :length="skillsPageLength[spec.title]"
                  ></v-pagination>
                </div>
              </v-col>
            </v-row>
          </v-window-item>
        </v-window>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-row>
          <v-col>
            <h3>{{ $t("Service distance coverage") }}</h3>
            <v-divider class="mb-4" />
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4">
            <label class="input-label required">{{ $t("Radius km") }}</label>
            <v-text-field
              :label="$t('Working area')"
              solo
              suffix="km"
              type="number"
              :step="1"
              :max="10000"
              :min="1"
              :rules="[rules.onlyDigits, rules.radiusKm]"
              v-model="data.technician.working_area"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-row>
          <v-col>
            <h3>{{ $t("Trade Licenses") }}</h3>
            <v-divider class="mb-4" />
          </v-col>
        </v-row>
        <v-row>
          <v-col md="6">
            <v-row>
              <v-col md="10">
                <models-list :selected.sync="selectedLicense" type="license">
                  <v-btn
                    :ripple="false"
                    @click="isShownAddNewCertificateBtn = true"
                    text
                    small
                    color="primary"
                    class="btn-add-new-item "
                  >
                  </v-btn>
                </models-list>
              </v-col>
              <v-col md="2">
                <v-btn fab color="primary" @click="addLicense" :disabled="!selectedLicense">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col md="6">
            <div class="h-choose-double-box">
              <div class="d-flex" v-for="license in getLicensesPageList" :key="license.id">
                <span class=" mr-auto">{{ license.title }}</span>
                <v-btn
                  icon
                  x-small
                  rounded
                  class=""
                  color="primary"
                  @click="removeLicense(license.id)"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
            <div class="text-center">
              <v-pagination
                v-model="licensePage"
                v-if="data.licenses.length > elementsPerPage"
                circle
                :length="licensesPageLength"
              ></v-pagination>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn color="primary" class="float-left" @click="submitTechnician">{{ $t("Save") }}</v-btn>
      </v-col>
    </v-row>
    <errors-snackbar :show.sync="showWarnings" :errors="warnings"> </errors-snackbar>
    <info-snackbar :show.sync="showInfo" :message="infoMessage"> </info-snackbar>
  </v-form>
</template>
<script>
import ModelsList from "@/components/ModelsList.vue";
import ErrorsSnackbar from "@/components/ErrorsSnackbar.vue";
import InfoSnackbar from "@/components/InfoSnackbar.vue";

import { map as _map, find as _find, startCase as _startCase, capitalize, without } from "lodash";

import {
  updateTechnicianProfile,
  getAllUserSpecializations,
  getAllUserEducation,
  getUserEducationList,
  deleteUserEducation,
  createUserEducation,
  deleteTechSkill,
  deleteLicenseTechnic
} from "@/api";
import { cleanSource } from "@/helpers/workingWithObjects";

export default {
  name: "expert-profile",
  components: { ModelsList, ErrorsSnackbar, InfoSnackbar },
  props: ["data", "isActive"],
  watch: {
    isActive: function(isActive) {
      if (isActive) {
        this.getEducationsKinds();
      }
    }
  },
  data: () => ({
    skillsPage: {
      mechanic: 1,
      electrician: 1,
      electromechanic: 1,
      electronic: 1
    },
    skillsPageLength: {
      mechanic: 1,
      electrician: 1,
      electromechanic: 1,
      electronic: 1
    },
    licensePage: 1,
    elementsPerPage: 10,
    educationKinds: [],
    educations: [{ kind: "", name: "" }],
    user_educations: [],
    specTechItems: [],
    selectedSkill: null,
    selectedToolSkill: null,
    showWarnings: false,
    showInfo: false,
    infoMessage: "",
    warnings: [],
    specsApi: {},
    activeSkillsToolsTab: 0,
    selectedLicense: null,
    isShownAddNewCertificateBtn: false,
    newCertificate: "",
    rules: {
      counter: value => (value && value.length <= 100) || "Max 100 characters",
      radiusKm: value => value < 10000 || "Working area should be less or equal 10000",
      onlyDigits: value => /^\d*$/.test(value) || "Input is invalid",
      education: value => {
        const pattern = /[^\da-zA-Z-+@ ]/;
        return !pattern.test(value) || "Input is invalid";
      }
    }
  }),
  computed: {
    skillToolsIsShown() {
      const hasActiveSpecializations = this.specTechItems.filter(function(item) {
        if (item.active) {
          return true;
        }
      });

      return hasActiveSpecializations.length;
    },

    selectedLicenses() {
      return this.data.licenses.map(i => i.id);
    },

    getLicensesPageList() {
      return this.sliceArrayByPages(this.data.licenses, this.licensePage);
    },

    getSkillToolsPageList() {
      const specialityListName = this.specTechItems[this.activeSkillsToolsTab]["title"];
      const userSkillsList = this.data.skills[specialityListName];

      return this.sliceArrayByPages(userSkillsList, this.skillsPage[specialityListName]);
    },

    licensesPageLength() {
      return Math.ceil(this.data.licenses.length / this.elementsPerPage);
    }
  },
  methods: {
    sliceArrayByPages(arrayList, currentPage) {
      const offset = this.elementsPerPage * currentPage;
      const index = offset - this.elementsPerPage;

      return arrayList.slice(index, offset);
    },
    getEducationList() {
      getUserEducationList()
        .then(r => {
          this.user_educations = r.data.user_educations;
        })
        .catch(err => {
          this.user_educations = [];
          console.log(err);
        });
    },

    getEducationsKinds() {
      getAllUserEducation()
        .then(r => {
          this.educationKinds = r.data.education_kinds;
          this.educations = _map(this.educationKinds, (id, title) => ({
            id,
            title,
            value: ""
          }));

          this.getEducationList();
        })
        .catch(err => {
          console.error(err);
        });
    },

    getSpecialisations() {
      getAllUserSpecializations()
        .then(r => {
          this.specsApi = r.data;
          const userSkillsTools = r.data["technician_specializations"];
          this.specTechItems = _map(userSkillsTools, (id, title) => ({
            text: _startCase(title),
            title: title,
            specialism: id,
            work_experience: "",
            hourly_rate: 0,
            active: false
          }));

          for (const spec in userSkillsTools) {
            this.skillsPageLength[spec] = Math.ceil(
              this.data.skills[spec].length / this.elementsPerPage
            );
          }

          this.workExperienceItems = _map(r.data["work_experiences"], (id, title) => ({
            text: capitalize(_startCase(title)),
            value: id
          }));
          this.setSpecialisations();
        })
        .catch(err => {
          console.error(err);
        });
    },

    getSkillsTools() {
      for (const index in this.data.skills) {
        _map(this.data.skills[index], item => {
          item["title"] =
            item["tool"] != null
              ? `${item["skill_name"]}     ←•→     ${item["tool"]}`
              : `${item["skill_name"]}`;
          return item;
        });
      }
    },

    addEducation(education) {
      if (!education.value) return;
      const data = {
        kind: education.id,
        name: education.value,
        user_profile_id: this.data.technician.id
      };
      createUserEducation(data)
        .then(() => {
          console.info("Education item was created");
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.getEducationList();
        });
    },

    removeEducation(education) {
      if (this.user_educations.length > 1) {
        deleteUserEducation(education.id)
          .then(() => {
            console.info("Education item was removed");
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            this.getEducationList();
          });
      } else {
        this.warnings = [this.$t("Please add education before delete the last one")];
        this.showWarnings = true;
      }
    },

    setSpecialisations() {
      if (!this.data.specializations.length) {
        return;
      }
      this.specTechItems = _map(this.specTechItems, item => {
        const spec = _find(this.data.specializations, i => i["specialism"] === item.title);
        if (spec) {
          return {
            text: _startCase(spec["specialism"]),
            title: spec["specialism"],
            specialism: this.specsApi["technician_specializations"][spec["specialism"]],
            work_experience: this.specsApi["work_experiences"][spec["work_experience"]],
            hourly_rate: spec["hourly_rate"] + "",
            active: true
          };
        } else {
          return item;
        }
      });
    },

    submitTechnician: function() {
      this.warnings = [];
      this.showWarnings = false;
      const userEducation = cleanSource(this.user_educations).map(educationItem => {
        educationItem.kind = this.educationKinds[educationItem.kind];
        return educationItem;
      });

      const skillsList = [];
      for (const index in this.data.skills) {
        const values = this.data.skills[index];
        _map(values, item => {
          skillsList.push(item.id);
        });
      }

      const data = {
        working_area: +this.data.technician.working_area,
        user_specializations: this.specTechItems.filter(i => i.active),
        user_educations: userEducation,
        additional_info: this.data.additionals,
        licenses_ids: this.data.licenses.map(i => i.id),
        skills_ids: skillsList
      };

      updateTechnicianProfile(data)
        .then(() => {
          this.showWarnings = false;
          this.infoMessage = this.$t("Technician profile was successfully updated!");
          this.showInfo = true;
        })
        .catch(err => {
          this.warnings =
            err.response.status !== 500
              ? [err.response.data.errors.join("\n ")]
              : [err.response.data.error];
          this.showWarnings = true;
        });
    },

    addSkillTool(specialization) {
      if (!this.selectedToolSkill) return;
      if (_find(this.data.skills[specialization], { id: this.selectedToolSkill.id })) return;
      this.data.skills[specialization].push(this.selectedToolSkill);
    },

    removeSkillTool(item, specialization) {
      deleteTechSkill(item.id)
        .then(() => {
          this.data.skills[specialization] = without(this.data.skills[specialization], item);
        })
        .catch(err => {
          this.warnings =
            err.response.status !== 500 ? err.response.data.errors : [err.response.data.error];
          this.showWarnings = true;
        });
    },

    addLicense() {
      if (!this.selectedLicense) return;
      if (_find(this.data.licenses, { id: this.selectedLicense.id })) return;
      this.data.licenses.push(this.selectedLicense);
    },

    removeLicense(license) {
      deleteLicenseTechnic(license)
        .catch(e => {
          this.warnings = e.data.errors;
          console.error(e);
        })
        .finally(() => {
          this.data.licenses = this.data.licenses.filter(i => i.id !== license);
        });
    }
  },
  mounted() {
    this.getEducationsKinds();
    this.getSpecialisations();
    this.getSkillsTools();
  }
};
</script>
