<script>
import { getProfileIDs } from "@/api";

export default {
  methods: {
    getProfileIDs(id) {
      getProfileIDs(id)
        .then(r => {
          this.getData(r.data);
        })
        .catch(err => {
          console.error(err);
        });
    }
  },
  mounted() {
    this.getProfileIDs(this.$route.params["id"]);
  }
};
</script>
